import { Alert, AlertTitle, Slide } from "@mui/material"
import React from "react"

export const alertProps = {
    open : false,
    severity : "info",
    alertTitle : "",
    statusMsg : ""
}

export const StatusAlert = (props) => {

    const { open, severity, alertTitle, statusMsg } = props

    return (
        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
            <Alert severity={severity}>
                <AlertTitle>{alertTitle}</AlertTitle>
                {statusMsg}
            </Alert>
        </Slide>
    )
}