import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChevronLeft, ChevronRight, Edit, Search } from "@mui/icons-material"
import { alertProps, StatusAlert } from "./utills"

export const CreateLocation = (props) => {

    const { setIsLoading, addToLocations, setAlert, organisation } = props
    const locationDefaults = {
        name: "",
        address: "",
        license_no: "",
        gcp: ""
    }
    const [open, setOpen] = useState(false)
    const [location, setLocation] = useState(locationDefaults)

    const handleClose = () => {
        setOpen(false)
    }

    const setFormFields = (field, value) => {
        const formObj = { ...location }
        formObj[field] = value
        setLocation(formObj)
    }

    const CreateLocation = () => {
        setIsLoading(true)
        axios.post('/api/location', location).then(resp => {
            setIsLoading(false)
            setLocation(locationDefaults)
            addToLocations(resp.data)
            setOpen(false)
            setAlert({
                open: true,
                severity: "success",
                alertTitle: "Location Created Successfully",
                statusMsg: resp.data.name
            })
            setTimeout(() => { setAlert(alertProps) }, 3000)
        }).catch(err => {
            setIsLoading(false)
            setOpen(false)
            console.log(err)
            setAlert({
                open: true,
                severity: "error",
                alertTitle: "Location creation failed",
                statusMsg: JSON.stringify(err.response.data.message)
            })
        })
    }

    return (
        <React.Fragment>
            <Button sx={{ marginTop: 2 }} variant="contained" onClick={() => { setOpen(true) }}>
                Create
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-create-location"
                aria-describedby="dialog-create-location-description"
                fullWidth
            >
                <DialogTitle id="dialog-create-location">
                    {"Create New Location"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-name">Location Name</InputLabel>
                        <OutlinedInput
                            id="create-location-name"
                            type='text'
                            label="Location Name"
                            value={location.name}
                            onChange={(event) => { setFormFields("name", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-address">Address</InputLabel>
                        <OutlinedInput
                            id="create-location-address"
                            type='text'
                            label="Address"
                            value={location.address}
                            onChange={(event) => { setFormFields("address", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-license">License No</InputLabel>
                        <OutlinedInput
                            id="create-location-license"
                            type='text'
                            label="License No"
                            value={location.license_no}
                            onChange={(event) => { setFormFields("license_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-gcp">GCP</InputLabel>
                        <Select
                            labelId="select-location-gcp-label"
                            id="select-location-gcp"
                            value={location.gcp}
                            label="GCP"
                            onChange={(event) => { setFormFields("gcp", event.target.value) }}
                        >
                            {
                                organisation.map((org,index) => {

                                    return(
                                        <MenuItem key={index} value={org.gcp}>{org.gcp} - {org.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={CreateLocation} autoFocus>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const UpdateLocation = (props) => {

    const { setIsLoading, setAlert, locationDefaults } = props
    const [open, setOpen] = useState(false)
    const [location, setLocation] = useState(locationDefaults)

    const handleClose = () => {
        setOpen(false)
    }

    const setFormFields = (field, value) => {
        const formObj = { ...location }
        formObj[field] = value
        setLocation(formObj)
    }

    const updateLocation = () => {
        setIsLoading(true)
        axios.put('/api/location', location).then(resp => {
            setIsLoading(false)
            setOpen(false)
            setAlert({
                open: true,
                severity: "success",
                alertTitle: "Location updated Successfully",
                statusMsg: resp.data.name
            })
            setTimeout(() => { setAlert(alertProps) }, 3000)
        }).catch(err => {
            setIsLoading(false)
            setOpen(false)
            console.log(err)
            setAlert({
                open: true,
                severity: "error",
                alertTitle: "Location update failed",
                statusMsg: JSON.stringify(err.response.data.message)
            })
        })
    }


    return (
        <React.Fragment>
            <IconButton sx={{ position: "absolute", top: 1, right: 2 }} onClick={() => { setOpen(true) }}>
                <Edit />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-create-location"
                aria-describedby="dialog-create-location-description"
                fullWidth
            >
                <DialogTitle id="dialog-create-location">
                    {"Create New Location"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-name">Location Name</InputLabel>
                        <OutlinedInput
                            id="create-location-name"
                            type='text'
                            label="Location Name"
                            value={location.name}
                            onChange={(event) => { setFormFields("name", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-address">Address</InputLabel>
                        <OutlinedInput
                            id="create-location-address"
                            type='text'
                            label="Address"
                            value={location.address}
                            onChange={(event) => { setFormFields("address", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-location-license">License No</InputLabel>
                        <OutlinedInput
                            id="create-location-license"
                            type='text'
                            label="License No"
                            value={location.license_no}
                            onChange={(event) => { setFormFields("license_no", event.target.value) }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={updateLocation} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}

const LocationItem = (props) => {
    const expanded = props.expanded
    const handleChange = props.handleChange
    return (
        <Accordion expanded={expanded === props.index} onChange={handleChange(props.index)} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={props.index + "-content"}
                id={props.index + "-header"}
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {props.location.name}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{props.location.code}</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <Box sx={{ flexGrow: 1 }} position={"relative"}>
                    <UpdateLocation
                        locationDefaults={props.location}
                        setIsLoading={props.setIsLoading}
                        setAlert={props.setAlert}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}><span>Name :</span></Grid>
                        <Grid item xs={6}><span>{props.location.name}</span></Grid>
                        <Grid item xs={6}><span>Address:</span></Grid>
                        <Grid item xs={6}><span>{props.location.address}</span></Grid>
                        <Grid item xs={6}><span>License No: </span></Grid>
                        <Grid item xs={6}><span>{props.location.license_no}</span></Grid>
                    </Grid>
                </Box>

            </AccordionDetails>
        </Accordion>
    )
}

export const GetLocation = (props) => {

    const [expanded, setExpanded] = useState(false)
    const { alert, setAlert, setIsLoading } = props

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <React.Fragment>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatusAlert
                            open={alert.open}
                            severity={alert.severity}
                            alertTitle={alert.alertTitle}
                            statusMsg={alert.statusMsg}
                        />
                        {
                            props.locations.map((location, index) => {
                                return <LocationItem
                                    key={index}
                                    index={index}
                                    location={location}
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    setAlert={setAlert}
                                    setIsLoading={setIsLoading}
                                />
                            })
                        }
                    </Grid>
                </Grid>
            </Box>

        </React.Fragment>
    )
}

const LocationSearch = (props) => {

    const { getLocations, search, setSearch } = props
    return (
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-location">Location</InputLabel>
            <OutlinedInput
                id="outlined-adornment-location"
                type='text'
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="location search"
                            onClick={() => { search.length > 2 ? getLocations(search, 1) : alert("Enter minimum 3 charaters to search") }}
                            edge="end"
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
                label="Location"
                value={search}
                onChange={(event) => { setSearch(event.target.value) }}
            />
        </FormControl>
    )
}

const SearchControls = (props) => {

    const { page, pages, hasNext, hasPrev, getLocations, search } = props
    return (
        <Box sx={{ flexGrow: 1, marginTop: 1 }}>
            <IconButton disabled={hasPrev} onClick={() => { getLocations(search, Number(page) - 1) }}>
                <ChevronLeft sx={{ fontSize: 38 }} />
            </IconButton>
            <span > {page} / {pages} </span>
            <IconButton disabled={hasNext} onClick={() => { getLocations(search, Number(page) + 1) }}>
                <ChevronRight sx={{ fontSize: 38 }} />
            </IconButton>
        </Box>

    )
}

export const Locations = (props) => {

    const [locations, setLocations] = useState([])
    const [organisation, setOrganisation] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [page, setPage] = useState("")
    const [pages, setPages] = useState("")
    const [search, setSearch] = useState("")
    const [alert, setAlert] = useState(alertProps)

    function getLocations(key, page) {
        setIsLoading(true)
        axios.get('/api/location?' + (key.length > 2 ? 'key=' + key + '&' : '') + 'page=' + page).then(resp => {
            setLocations(resp.data)
            setHasNext(resp.headers['x-has-next'])
            setHasPrev(resp.headers['x-has-prev'])
            setPage(resp.headers['x-page'])
            setPages(resp.headers['x-pages'])
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    const getOrganisation = (key,page) => {
        axios.get('/api/organisation').then(resp => {
            setOrganisation(resp.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }


    const addToLocations = (location) => {

        const locationsList = [...locations]
        locationsList.push(location)
        setLocations(locationsList)

    }

    useEffect(() => {
        const loadInitialData = async () => {
            await getLocations("",1)
            await getOrganisation("",1)
        }
        loadInitialData()
    }, [])

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <LocationSearch
                            getLocations={getLocations}
                            search={search}
                            setSearch={setSearch}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <SearchControls
                            page={page}
                            pages={pages}
                            hasNext={hasNext}
                            hasPrev={hasPrev}
                            getLocations={getLocations}
                            search={search}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <CreateLocation 
                        setIsLoading={setIsLoading} 
                        addToLocations={addToLocations} 
                        setAlert={setAlert} 
                        organisation = {organisation}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            isLoading ? <Backdrop
                                sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                                : <GetLocation locations={locations} setAlert={setAlert} alert={alert} setIsLoading={setIsLoading} />
                        }
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}