import axios from "axios"
import React, { useEffect, useState } from "react"
import { alertProps, StatusAlert } from "./utills"
import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChevronLeft, ChevronRight, Print, Search } from "@mui/icons-material"
import qz from "qz-tray"

export const CreateLabel = (props) => {

    const labelDefaults = {
        format_id: 1,
        location_id: 1,
        product_id: "",
        batch_no: "",
        gross_wt: "",
        tare_wt: "",
        net_wt: "",
        mfg_date: "",
        exp_date: null,
        retest_date: "",
        batch_size: "",
        container_no: "",
        total_containers: ""

    }

    const { setIsLoading, products, setAlert } = props
    const [open, setOpen] = useState(false)
    const [label, setLabel] = useState(labelDefaults)

    const handleClose = () => {
        setOpen(false)
    }

    const setFormFields = (field, value) => {
        const formObj = { ...label }
        formObj[field] = value
        setLabel(formObj)
    }

    const createLabel = () => {
        setIsLoading(true)
        axios.post('/api/print', label).then(resp => {
            setIsLoading(false)
            setLabel(labelDefaults)
            //addToProducts(resp.data)
            setOpen(false)
            setAlert({
                open: true,
                severity: "success",
                alertTitle: "Label Created Successfully",
                statusMsg: resp.data.name
            })
            setTimeout(() => { setAlert(alertProps) }, 3000)
            window.location.reload()
        }).catch(err => {
            setIsLoading(false)
            setOpen(false)
            console.log(err)
            setAlert({
                open: true,
                severity: "error",
                alertTitle: "Label creation failed",
                statusMsg: JSON.stringify(err.response.data.message)
            })
        })
    }



    return (
        <React.Fragment>
            <Button sx={{ marginTop: 2 }} variant="contained" onClick={() => { setOpen(true) }}>
                Create
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-create-label"
                aria-describedby="dialog-create-label-description"
                fullWidth
            >
                <DialogTitle id="dialog-create-label">
                    {"Create New Label"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-label-product">Product</InputLabel>
                        <Select
                            labelId="select-product-sscc-label"
                            id="select-product-sscc"
                            value={label.product_id}
                            label="Product"
                            onChange={(event) => { setFormFields("product_id", event.target.value) }}
                        >
                            {
                                products.map((product, index) => {

                                    return (
                                        <MenuItem key={index} value={product.id}>{product.name}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-batchNo">Batch No</InputLabel>
                        <OutlinedInput
                            id="create-label-batchNo"
                            type='text'
                            label="Batch No"
                            value={label.batch_no}
                            onChange={(event) => { setFormFields("batch_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-grosswt">Gross Wt</InputLabel>
                        <OutlinedInput
                            id="create-label-grosswt"
                            type='text'
                            label="Gross Wt"
                            value={label.gross_wt}
                            onChange={(event) => { setFormFields("gross_wt", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-tarewt">Tare Wt</InputLabel>
                        <OutlinedInput
                            id="create-label-tarewt"
                            type='text'
                            label="Tare Wt"
                            value={label.tare_wt}
                            onChange={(event) => { setFormFields("tare_wt", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-netwt">Net Wt</InputLabel>
                        <OutlinedInput
                            id="create-label-netwt"
                            type='text'
                            label="Net Wt"
                            value={label.net_wt}
                            onChange={(event) => { setFormFields("net_wt", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-mfgdt">Mfg Date</InputLabel>
                        <OutlinedInput
                            id="create-label-msgdt"
                            type='text'
                            label="Mfg Date"
                            value={label.mfg_date}
                            onChange={(event) => { setFormFields("mfg_date", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-retestdt">Retest Date</InputLabel>
                        <OutlinedInput
                            id="create-label-retestdt"
                            type='text'
                            label="Retest Date"
                            value={label.retest_date}
                            onChange={(event) => { setFormFields("retest_date", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-batchsize">Batch Size</InputLabel>
                        <OutlinedInput
                            id="create-label-batchsize"
                            type='text'
                            label="Batch Size"
                            value={label.batch_size}
                            onChange={(event) => { setFormFields("batch_size", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-cno">Container No</InputLabel>
                        <OutlinedInput
                            id="create-label-cno"
                            type='Number'
                            label="Container No"
                            value={label.container_no}
                            onChange={(event) => { setFormFields("container_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-Label-tno">Total COntainers</InputLabel>
                        <OutlinedInput
                            id="create-label-tno"
                            type='Number'
                            label="Total COntainers"
                            value={label.total_containers}
                            onChange={(event) => { setFormFields("total_containers", event.target.value) }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createLabel} autoFocus>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}

export const GetLabel = (props) => {

    const [expanded, setExpanded] = useState(false)
    const { alert, setAlert, setIsLoading } = props

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatusAlert
                            open={alert.open}
                            severity={alert.severity}
                            alertTitle={alert.alertTitle}
                            statusMsg={alert.statusMsg}
                        />
                        {
                            props.labels.map((label, index) => {
                                return <LabelItem
                                    key={index}
                                    index={index}
                                    label={label}
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    setAlert={setAlert}
                                    setIsLoading={setIsLoading}
                                />
                            })
                        }
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )

}

const UpdateLabel = (props) => {

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

const LabelItem = (props) => {

    const expanded = props.expanded
    const handleChange = props.handleChange

    return (
        <Accordion expanded={expanded === props.index} onChange={handleChange(props.index)} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={props.index + "-content"}
                id={props.index + "-header"}
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {
                        props.label.sscc.batch_no
                    }
                </Typography>
                <Typography sx={{ color: 'text.secondary', width: '33%', flexShrink: 0 }}>{props.label.sscc.product.name}</Typography>
                <Typography sx={{ color: 'text.secondary' }}>{props.label.sscc.container_no + "/" + props.label.sscc.total_containers}</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <Box sx={{ flexGrow: 1 }} position={"relative"}>
                    <UpdateLabel
                        labelDefaults={props.label}
                        setIsLoading={props.setIsLoading}
                        setAlert={props.setAlert}
                    />
                    <PrintLabel 
                    label = {props.label}
                    setIsLoading={props.setIsLoading}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={3}><span>Name :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.product.name}</span></Grid>
                        <Grid item xs={3}><span>CAS No :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.product.cas_no}</span></Grid>
                        <Grid item xs={3}><span>Container No. :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.container_no + "/" + props.label.sscc.total_containers}</span></Grid>

                        <Grid item xs={3}><span>Gross Wt. :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.gross_wt}</span></Grid>
                        <Grid item xs={3}><span>Retest Date :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.retest_date}</span></Grid>
                        <Grid item xs={3}><span>Net Wt. :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.net_wt}</span></Grid>

                        <Grid item xs={3}><span>Storage Condition :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.product.storage_condition}</span></Grid>
                        <Grid item xs={3}><span>Tare Wt. :</span></Grid>
                        <Grid item xs={3}><span>{props.label.sscc.tare_wt}</span></Grid>
                    </Grid>
                </Box>

            </AccordionDetails>
        </Accordion>
    )
}


const LabelSearch = (props) => {

    const { getLabels, search, setSearch } = props
    return (
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-label">Batch No</InputLabel>
            <OutlinedInput
                id="outlined-adornment-label"
                type='text'
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="label search"
                            onClick={() => { search.length > 2 ? getLabels(search, 1) : alert("Enter minimum 3 charaters to search") }}
                            edge="end"
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
                label="Batch No"
                value={search}
                onChange={(event) => { setSearch(event.target.value) }}
            />
        </FormControl>
    )
}

const SearchControls = (props) => {

    const { page, pages, hasNext, hasPrev, getProducts, search } = props
    return (
        <Box sx={{ flexGrow: 1, marginTop: 1 }}>
            <IconButton disabled={hasPrev} onClick={() => { getProducts(search, Number(page) - 1) }}>
                <ChevronLeft sx={{ fontSize: 38 }} />
            </IconButton>
            <span > {page} / {pages} </span>
            <IconButton disabled={hasNext} onClick={() => { getProducts(search, Number(page) + 1) }}>
                <ChevronRight sx={{ fontSize: 38 }} />
            </IconButton>
        </Box>

    )
}

const PrintLabel = (props) => {

    const { label, setIsLoading } = props

    const printLabel = async() => {
        setIsLoading(true)
        await axios.put('/api/print?print=True',{id:label.id} ).then(async (resp) => {
            await qz.websocket.connect().then(function() { 
                return qz.printers.find("Generic / Text Only");              // Pass the printer name into the next Promise
            }).then(function(printer) {
                var config = qz.configs.create(printer);       // Create a default config for the found printer
                var data = [label.zpl];   // Raw ZPL
                return qz.print(config,data)
            })
            .then(()=>{
                return qz.websocket.disconnect()
            })
            .catch(function(e) { 
                console.error(e); 
            });            
        }).catch(err => {
            setIsLoading(false)
        })
        setIsLoading(false)
        window.location.reload()
    }

    const reprintLabel = () => {
        qz.websocket.connect().then(function() { 
            return qz.printers.find("Generic / Text Only");              // Pass the printer name into the next Promise
         }).then(function(printer) {
            var config = qz.configs.create(printer);       // Create a default config for the found printer
            var data = [label.zpl];   // Raw ZPL
            return qz.print(config,data)
         })
         .then(()=>{
            return qz.websocket.disconnect()
         })
         .catch(function(e) { 
            console.error(e); 
        });
    }
    return (
        label.is_printed?

        <IconButton sx={{ position: "absolute", top: 1, right: 2 }} onClick={reprintLabel} >
            <Print />
        </IconButton>
        :
        <IconButton sx={{ position: "absolute", top: 1, right: 2 }} onClick={printLabel} >
            <Print />
        </IconButton>
    )
}
export const Labels = (props) => {

    const [labels, setLabels] = useState([])
    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [page, setPage] = useState("")
    const [pages, setPages] = useState("")
    const [search, setSearch] = useState("")
    const [alert, setAlert] = useState(alertProps)

    function getLabels(key, page) {
        setIsLoading(true)
        axios.get('/api/print?' + (key.length > 2 ? 'key=' + key + '&' : '') + 'page=' + page).then(resp => {
            setLabels(resp.data)
            setHasNext(resp.headers['x-has-next'])
            setHasPrev(resp.headers['x-has-prev'])
            setPage(resp.headers['x-page'])
            setPages(resp.headers['x-pages'])
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    const getProducts = () => {
        setIsLoading(true)
        axios.get('/api/product?page=1').then(resp => {
            setProducts(resp.data)
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        const loadInitialData = async () => {
            await getLabels("", 1)
            await getProducts()

        }
        loadInitialData()
    }, [])

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <LabelSearch
                            getLabels={getLabels}
                            search={search}
                            setSearch={setSearch}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <SearchControls
                            page={page}
                            pages={pages}
                            hasNext={hasNext}
                            hasPrev={hasPrev}
                            getLabels={getLabels}
                            search={search}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <CreateLabel
                            setIsLoading={setIsLoading}
                            setAlert={setAlert}
                            products={products}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            isLoading ? <Backdrop
                                sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                                : <GetLabel labels={labels} setAlert={setAlert} alert={alert} setIsLoading={setIsLoading} />

                        }
                    </Grid>
                </Grid>

            </Box>
        </React.Fragment>
    )
}