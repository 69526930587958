import React from "react"

export const CreateLabelFormat = (props) => {

    return(
        <React.Fragment>

        </React.Fragment>
    )
}

export const GetLabelFormat = (props) => {

    return(
        <React.Fragment>
            
        </React.Fragment>
    )
}

export const LabelFormats = (props) => {

    return(
        <React.Fragment>
            
        </React.Fragment>
    )
}