import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material"
import axios from "axios"
import React, { useEffect, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ChevronLeft, ChevronRight, Edit, Search } from "@mui/icons-material"
import { alertProps, StatusAlert } from "./utills"

export const CreateProduct = (props) => {

    const productDefaults = {
        name: "",
        gtin: null,
        cas_no: "",
        ndc_no: "",
        storage_condition: ""
    }
    const { setIsLoading, addToProducts, setAlert } = props
    const [open, setOpen] = useState(false)
    const [product, setProduct] = useState(productDefaults)

    const handleClose = () => {
        setOpen(false)
    }

    const setFormFields = (field, value) => {
        const formObj = { ...product }
        formObj[field] = value
        setProduct(formObj)
    }

    const createProduct = () => {
        setIsLoading(true)
        axios.post('/api/product', product).then(resp => {
            setIsLoading(false)
            setProduct(productDefaults)
            addToProducts(resp.data)
            setOpen(false)
            setAlert({
                open: true,
                severity: "success",
                alertTitle: "Product Created Successfully",
                statusMsg: resp.data.name
            })
            setTimeout(() => { setAlert(alertProps) }, 3000)
        }).catch(err => {
            setIsLoading(false)
            setOpen(false)
            console.log(err)
            setAlert({
                open: true,
                severity: "error",
                alertTitle: "Product creation failed",
                statusMsg: JSON.stringify(err.response.data.message)
            })
        })
    }

    return (
        <React.Fragment>
            <Button sx={{ marginTop: 2 }} variant="contained" onClick={() => { setOpen(true) }}>
                Create
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-create-product"
                aria-describedby="dialog-create-product-description"
                fullWidth
            >
                <DialogTitle id="dialog-create-product">
                    {"Create New Product"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-name">Product Name</InputLabel>
                        <OutlinedInput
                            id="create-product-name"
                            type='text'
                            label="Product Name"
                            value={product.name}
                            onChange={(event) => { setFormFields("name", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-gtin">Product GTIN</InputLabel>
                        <OutlinedInput
                            id="create-product-gtin"
                            type='text'
                            label="Product GTIN"
                            value={product.gtin}
                            onChange={(event) => { setFormFields("gtin", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-cas">Product CAS No</InputLabel>
                        <OutlinedInput
                            id="create-product-cas"
                            type='text'
                            label="Product CAS No"
                            value={product.cas_no}
                            onChange={(event) => { setFormFields("cas_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-ndc">Product NDC No</InputLabel>
                        <OutlinedInput
                            id="create-product-ndc"
                            type='text'
                            label="Product NDC No"
                            value={product.ndc_no}
                            onChange={(event) => { setFormFields("ndc_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-storage-condition">Storage Condition</InputLabel>
                        <OutlinedInput
                            id="create-product-storage-condition"
                            type='text'
                            label="Storage Condition"
                            value={product.storage_condition}
                            onChange={(event) => { setFormFields("storage_condition", event.target.value) }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={createProduct} autoFocus>
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

const UpdateProduct = (props) => {

    const { setIsLoading, setAlert, productDefaults } = props
    const [open, setOpen] = useState(false)
    const [product, setProduct] = useState(productDefaults)

    const handleClose = () => {
        setOpen(false)
    }

    const setFormFields = (field, value) => {
        const formObj = { ...product }
        formObj[field] = value
        setProduct(formObj)
    }

    const updateProduct = () => {
        setIsLoading(true)
        axios.put('/api/product', product).then(resp => {
            setIsLoading(false)
            setOpen(false)
            setAlert({
                open: true,
                severity: "success",
                alertTitle: "Product updated Successfully",
                statusMsg: resp.data.name
            })
            setTimeout(() => { setAlert(alertProps) }, 3000)
        }).catch(err => {
            setIsLoading(false)
            setOpen(false)
            console.log(err)
            setAlert({
                open: true,
                severity: "error",
                alertTitle: "Product update failed",
                statusMsg: JSON.stringify(err.response.data.message)
            })
        })
    }


    return (
        <React.Fragment>
            <IconButton sx={{ position: "absolute", top: 1, right: 2 }} onClick={() => { setOpen(true) }}>
                <Edit />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-create-product"
                aria-describedby="dialog-create-product-description"
                fullWidth
            >
                <DialogTitle id="dialog-create-product">
                    {"Create New Product"}
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-name">Product Name</InputLabel>
                        <OutlinedInput
                            id="create-product-name"
                            type='text'
                            label="Product Name"
                            value={product.name}
                            onChange={(event) => { setFormFields("name", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-gtin">Product GTIN</InputLabel>
                        <OutlinedInput
                            id="create-product-gtin"
                            type='text'
                            label="Product GTIN"
                            value={product.gtin}
                            onChange={(event) => { setFormFields("gtin", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-cas">Product CAS No</InputLabel>
                        <OutlinedInput
                            id="create-product-cas"
                            type='text'
                            label="Product CAS No"
                            value={product.cas_no}
                            onChange={(event) => { setFormFields("cas_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-ndc">Product NDC No</InputLabel>
                        <OutlinedInput
                            id="create-product-ndc"
                            type='text'
                            label="Product NDC No"
                            value={product.ndc_no}
                            onChange={(event) => { setFormFields("ndc_no", event.target.value) }}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }}>
                        <InputLabel htmlFor="create-product-storage-condition">Storage Condition</InputLabel>
                        <OutlinedInput
                            id="create-product-storage-condition"
                            type='text'
                            label="Storage Condition"
                            value={product.storage_condition}
                            onChange={(event) => { setFormFields("storage_condition", event.target.value) }}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={updateProduct} autoFocus>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )

}

const ProductItem = (props) => {
    const expanded = props.expanded
    const handleChange = props.handleChange
    return (
        <Accordion expanded={expanded === props.index} onChange={handleChange(props.index)} >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={props.index + "-content"}
                id={props.index + "-header"}
            >
                <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {props.product.name}
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>{props.product.code}</Typography>
            </AccordionSummary>
            <AccordionDetails>

                <Box sx={{ flexGrow: 1 }} position={"relative"}>
                    <UpdateProduct
                    productDefaults = {props.product}
                    setIsLoading = {props.setIsLoading}
                    setAlert = {props.setAlert}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}><span>Name :</span></Grid>
                        <Grid item xs={6}><span>{props.product.name}</span></Grid>
                        <Grid item xs={6}><span>CAS No :</span></Grid>
                        <Grid item xs={6}><span>{props.product.cas_no}</span></Grid>
                        <Grid item xs={6}><span>Storage Condition :</span></Grid>
                        <Grid item xs={6}><span>{props.product.storage_condition}</span></Grid>
                    </Grid>
                </Box>

            </AccordionDetails>
        </Accordion>
    )
}

export const GetProduct = (props) => {

    const [expanded, setExpanded] = useState(false)
    const { alert, setAlert, setIsLoading } = props

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <React.Fragment>

            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <StatusAlert
                            open={alert.open}
                            severity={alert.severity}
                            alertTitle={alert.alertTitle}
                            statusMsg={alert.statusMsg}
                        />
                        {
                            props.products.map((product, index) => {
                                return <ProductItem
                                    key={index}
                                    index={index}
                                    product={product}
                                    expanded={expanded}
                                    handleChange={handleChange}
                                    setAlert={setAlert}
                                    setIsLoading={setIsLoading}
                                    />
                            })
                        }
                    </Grid>
                </Grid>
            </Box>

        </React.Fragment>
    )
}

const ProductSearch = (props) => {

    const { getProducts, search, setSearch } = props
    return (
        <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-product">Product</InputLabel>
            <OutlinedInput
                id="outlined-adornment-product"
                type='text'
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="product search"
                            onClick={() => { search.length > 2 ? getProducts(search, 1) : alert("Enter minimum 3 charaters to search") }}
                            edge="end"
                        >
                            <Search />
                        </IconButton>
                    </InputAdornment>
                }
                label="Product"
                value={search}
                onChange={(event) => { setSearch(event.target.value) }}
            />
        </FormControl>
    )
}

const SearchControls = (props) => {

    const { page, pages, hasNext, hasPrev, getProducts, search } = props
    return (
        <Box sx={{ flexGrow: 1, marginTop: 1 }}>
            <IconButton disabled={hasPrev} onClick={() => { getProducts(search, Number(page) - 1) }}>
                <ChevronLeft sx={{ fontSize: 38 }} />
            </IconButton>
            <span > {page} / {pages} </span>
            <IconButton disabled={hasNext} onClick={() => { getProducts(search, Number(page) + 1) }}>
                <ChevronRight sx={{ fontSize: 38 }} />
            </IconButton>
        </Box>

    )
}

export const Products = (props) => {

    const [products, setProducts] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [page, setPage] = useState("")
    const [pages, setPages] = useState("")
    const [search, setSearch] = useState("")
    const [alert, setAlert] = useState(alertProps)

    function getProducts(key, page) {
        setIsLoading(true)
        axios.get('/api/product?' + (key.length > 2 ? 'key=' + key + '&' : '') + 'page=' + page).then(resp => {
            setProducts(resp.data)
            setHasNext(resp.headers['x-has-next'])
            setHasPrev(resp.headers['x-has-prev'])
            setPage(resp.headers['x-page'])
            setPages(resp.headers['x-pages'])
            setIsLoading(false)
        }).catch(err => {
            setIsLoading(false)
        })
    }

    const addToProducts = (product) => {

        const productsList = [...products]
        productsList.push(product)
        setProducts(productsList)

    }

    useEffect(() => {
        getProducts("", 1)
    }, [])

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item md={8}>
                        <ProductSearch
                            getProducts={getProducts}
                            search={search}
                            setSearch={setSearch}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <SearchControls
                            page={page}
                            pages={pages}
                            hasNext={hasNext}
                            hasPrev={hasPrev}
                            getProducts={getProducts}
                            search={search}
                        />
                    </Grid>
                    <Grid item md={2}>
                        <CreateProduct setIsLoading={setIsLoading} addToProducts={addToProducts} setAlert={setAlert} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {
                            isLoading ? <Backdrop
                                sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                                open={isLoading}
                            >
                                <CircularProgress color="inherit" />
                            </Backdrop>
                                : <GetProduct products={products} setAlert={setAlert} alert={alert} setIsLoading={setIsLoading} />
                        }
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    )
}