import { Button, FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";

export const Login = (props) => {

    return (
        <React.Fragment>

            <form action="/login" method="POST">
                <Grid container spacing={2} direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel htmlFor="create-username">User Name</InputLabel>
                            <OutlinedInput
                                id="usernme"
                                type='text'
                                label="User Name"
                                name="userid"
                            />
                        </FormControl>
                        <Grid item xs={12}>
                            <FormControl sx={{ m: 1 }}>
                                <InputLabel htmlFor="create-password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    type='password'
                                    label="Password"
                                    name="password"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button type="submit">Login</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

        </React.Fragment>
    )
}
