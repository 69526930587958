import { createBrowserRouter } from 'react-router-dom';
import './App.css';
import BaseLayout from './components/base-layout';
import {  LabelFormats } from './components/label-formats';
import {  Products } from './components/products';
import { Locations } from './components/locations';
import { GetSSCC } from './components/sscc-scan';
import { Labels } from './components/label';
import { Login } from './components/login';


export const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    children:[
      {
        path:"label-formats",
        element:<LabelFormats/>
      },
      {
        path:"products",
        element: <Products/>
      },
      {
        path:"locations",
        element: <Locations/>
      },
      {
        path:"labels",
        element: <Labels/>
      }
    ]
  },
  {
    path:"login",
    element: <Login/>
  },
  {
    path:"/sscc/:sscc",
    element: <GetSSCC/>
  }
])

function App() {
  return (
    <BaseLayout/>
  );
}

export default App;
