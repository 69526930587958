import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { router } from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { initAxios } from './config';
import qz from "qz-tray"
import { hextorstr, KEYUTIL, stob64 } from 'jsrsasign';
import { KJUR } from 'jsrsasign';

initAxios()

qz.security.setCertificatePromise(function(resolve, reject) {
    resolve("-----BEGIN CERTIFICATE-----\n"+
"MIIECzCCAvOgAwIBAgIGAZMHr7VYMA0GCSqGSIb3DQEBCwUAMIGiMQswCQYDVQQG\n"+
"EwJVUzELMAkGA1UECAwCTlkxEjAQBgNVBAcMCUNhbmFzdG90YTEbMBkGA1UECgwS\n"+
"UVogSW5kdXN0cmllcywgTExDMRswGQYDVQQLDBJRWiBJbmR1c3RyaWVzLCBMTEMx\n"+
"HDAaBgkqhkiG9w0BCQEWDXN1cHBvcnRAcXouaW8xGjAYBgNVBAMMEVFaIFRyYXkg\n"+
"RGVtbyBDZXJ0MB4XDTI0MTEwNjE3MzI1NloXDTQ0MTEwNjE3MzI1NlowgaIxCzAJ\n"+
"BgNVBAYTAlVTMQswCQYDVQQIDAJOWTESMBAGA1UEBwwJQ2FuYXN0b3RhMRswGQYD\n"+
"VQQKDBJRWiBJbmR1c3RyaWVzLCBMTEMxGzAZBgNVBAsMElFaIEluZHVzdHJpZXMs\n"+
"IExMQzEcMBoGCSqGSIb3DQEJARYNc3VwcG9ydEBxei5pbzEaMBgGA1UEAwwRUVog\n"+
"VHJheSBEZW1vIENlcnQwggEiMA0GCSqGSIb3DQEBAQUAA4IBDwAwggEKAoIBAQCj\n"+
"bQNkBU+YETLezYAi+9tJkyFuN10qP1US0NzJvdilWIhfMqLHCuF1Zq+ldV50XWc4\n"+
"od1NTRJsgBpyxSnszfx1/KVEl1rSxkUjw8JiI76i8TpQlIwTyMOpnQPKIJFgbkwj\n"+
"D6EUIWhJrfz0ylv4dUw1iAqTuXSXs1S+1PTkEeVmT2Z9YblIjwi3t9gBzb7k9Ip0\n"+
"F9PW+Y+x05viHhgypxeg/aCXuD2BR2n94JTdooUH3KiArMm5KmleS6NOiHMenkZA\n"+
"La0CqL98i7czwcu3vljxYIiHz91OqdJp19OUAjATyHkp+imzR63D7yGptz5XVvJf\n"+
"69nLXLng/cZru77x/WfLAgMBAAGjRTBDMBIGA1UdEwEB/wQIMAYBAf8CAQEwDgYD\n"+
"VR0PAQH/BAQDAgEGMB0GA1UdDgQWBBT0OOdC/vtQwSO4Bm3D8mg5aWzlejANBgkq\n"+
"hkiG9w0BAQsFAAOCAQEAA8Yz+H3WjTvMonZkrWkqu+wOyRAsXuMKbqKq5izlpYpK\n"+
"ZpM7/A9QFpGDwZzR85Z+CoROvz1kE+gsTengBnhX2qBawiQYihAgun9GC4FpJmqT\n"+
"1PCEgxx6ZlcPuffLCVxdqTQySWVNkel9hMwGzqVPtuRKDQN0dlbf45Ov/N21QOd+\n"+
"5TsIcizPQCL0CgzKDeipNAFRy8Ag8KxdG5MkbQCe8GwLWXGLESHgqJVqk3dGzfVn\n"+
"lEGVuTAP4xCJicY5EcqrVMnddjqMgJhaykxToXVMkNUWw3Sf8RZwkUOogn27RRRC\n"+
"1uPUMZjmQn5GK/eX9cHiJ2Bn9hRV/r5QADAE8Nv7ow==\n"+
"-----END CERTIFICATE-----");
});

var privateKey = "-----BEGIN PRIVATE KEY-----\n"+
"MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQCjbQNkBU+YETLe\n"+
"zYAi+9tJkyFuN10qP1US0NzJvdilWIhfMqLHCuF1Zq+ldV50XWc4od1NTRJsgBpy\n"+
"xSnszfx1/KVEl1rSxkUjw8JiI76i8TpQlIwTyMOpnQPKIJFgbkwjD6EUIWhJrfz0\n"+
"ylv4dUw1iAqTuXSXs1S+1PTkEeVmT2Z9YblIjwi3t9gBzb7k9Ip0F9PW+Y+x05vi\n"+
"Hhgypxeg/aCXuD2BR2n94JTdooUH3KiArMm5KmleS6NOiHMenkZALa0CqL98i7cz\n"+
"wcu3vljxYIiHz91OqdJp19OUAjATyHkp+imzR63D7yGptz5XVvJf69nLXLng/cZr\n"+
"u77x/WfLAgMBAAECggEAFxsoqZGeqU91M882WLvnS0XEKkMlpv2BV8G0FcoDwYz8\n"+
"6wjLvvyo1+KG3wvzKNyz5WvtUCqfeju6gZOi09pj4YKxEzubGcXyrJXW++013O/i\n"+
"YNY/3+Q41dhfWsq0A0BU/3gMPD5k8lXpN+sMidp7xe3QIQbNl01BpCGT5lMpbgd9\n"+
"xolwDd45YXMTni+X+XjnkWn/a81FUbqfAUxMuyDB0AAGV7YXfHavaqw4RMH7uIiS\n"+
"uagzq5BfOH+J6yjwTVTZR6ATzkwNE5HKHuyrzdKo30dFBlpbx3LYxfh+A1uBoeVf\n"+
"P2W8/suPppQDNBe3YfPnKJ8zFJisnhK+GAFU1JTLIQKBgQDhinOafal7bQXiqCnt\n"+
"MLKXJNL9EUiYCkRHm02i328QfkMy9ehUAeyubdcKE0qOOOBKuwpQ5cOxRMGBg25/\n"+
"LysslbCEw4RbxPbq+ohJQv3o9/e7n9uWOIUliRvs+fkHiH5hHUnIH99RZ/hw/Jda\n"+
"p+gUmilLwLH4U05xS262uuulcwKBgQC5fxSeArKELv/W7LKDREj+krFkutePah36\n"+
"tZDp0e8646+WscTiE+61/+KPkhYWA4ajAEppsTJeyEroaydW8jxhUdI2KSeAS3oe\n"+
"JesjBARh/wqi3eoph600yNQab46vKeaoPvHLC1pCG+BRTUkf7Fg8aUB9sQyGduHG\n"+
"xEK7PwleSQKBgQDZNmshtbZdPWF2qfg/DDBjaj6dJWEhtHmP8R0zKDahzshPMw9+\n"+
"oJ/eWcDJXkzmbhZtQner86yldA9c+USYzseo1706D8bQTwByy7gnvpIlJXN8SnfD\n"+
"1Ek/Hi47638P5z+3repaB4AeuaBGyK3HzH+yH8p3v2xnOWwJptlIuPqrywKBgEIV\n"+
"mA7C/z5AUD0NjntddHPdZv1v4yPgtlV/IeVrOUNNolyZcH7qgvyr8NcdQGkPUIP5\n"+
"soj86+kAik9K/XToT8KMF8vb1YrLWPIsqByNbdJLvkpPrQCmqXpIoefZHogg8V+3\n"+
"LQUdQkaTD9NnKUoKNVUwWh1tWfz9lWnuuadjyb6hAoGBAKob5iXpT+Gv7xFjwW+G\n"+
"f3pjg4p9YWAQcL4fTncKR9XnRIkXzHf1poCQZzIHYNt8NK5MK2O72YlumnnWUHGJ\n"+
"mFKhl0ts2BZa3cZ0+0M0eE9BZYOhEWIIxrk+SSSlMstgRiYNsV9tUa1G9og2wkff\n"+
"sFsF440OCAhSrGJbQw3y8MM+\n"+
"-----END PRIVATE KEY-----\n"

qz.security.setSignatureAlgorithm("SHA512");
qz.security.setSignaturePromise(function(toSign) {
    return function(resolve, reject) {
        try {
            var pk = KEYUTIL.getKey(privateKey);
            var sig = new KJUR.crypto.Signature({"alg": "SHA512withRSA"});
            sig.init(pk); 
            sig.updateString(toSign);
            var hex = sig.sign();
            console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
            resolve(stob64(hextorstr(hex)));
        } catch (err) {
            console.error(err);
            reject(err);
        }
    };
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
