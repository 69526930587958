import React from "react";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { NavLink, Outlet } from "react-router-dom";
import Logo from "../assets/logo.png"
import { IconButton } from "@mui/material";
import { Logout } from "@mui/icons-material";

const NavListItem = (props) => {

    return (
        <NavLink to={props.to} style={{ textDecoration: "none" }}>
            {(isActive) => (
                <ListItem disablePadding>
                    <ListItemButton>
                        <ListItemText primary={props.text} />
                    </ListItemButton>
                </ListItem>
            )}
        </NavLink>
    )
}

const BaseLayout = () => {

    const drawerWidth = 240;

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
                >
                    <Toolbar />
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <AppBar sx={{ backgroundColor: '#e6e6e6' }}>
                        <Toolbar>
                            <Box component="img"
                                src={Logo}
                                sx={{ height: 56 }}
                            />
                            <a style={{marginLeft:"auto"}} href="/logout">
                                <Box>
                                    <IconButton >
                                        <Logout color="error" />
                                    </IconButton>
                                </Box>
                            </a>
                        </Toolbar>
                    </AppBar>
                    <Toolbar />
                    <Divider />
                    <List>
                        {
                        // <NavListItem to="label-formats" text="Label Formats" />
                        // <Divider />
                        }
                        <NavListItem to="products" text="Products" />
                        <Divider />
                        <NavListItem to="locations" text="Locations" />
                        <Divider />
                        <NavListItem to="labels" text="Labels" />
                        <Divider />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </React.Fragment>
    )
}

export default BaseLayout