import { AppBar, Backdrop, Box, CircularProgress, CssBaseline, Grid, Toolbar } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Logo from "../assets/logo.png"

export const GetSSCC = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [ssccData, setSSCCData] = useState({
        location: {
            name: "",
            address: "",
            license_no: "",
            gcp: ""
        },
        product: {
            name: "",
            gtin: null,
            cas_no: "",
            ndc_no: "",
            storage_condition: ""
        },
        batch_no:"",
        gross_wt:"",
        tare_wt:"",
        net_wt:"",
        mfg_date:"",
        retest_date:"",
        batch_size:"",
        container_no:"",
        total_containers:"",
        sscc_code:""
    })
    
    const { sscc } = useParams()
    
    useEffect(() => {
        const getSSCCData = () => {
            setIsLoading(true)
            axios.get("/api/sscc/" + sscc).then(resp => {
                setIsLoading(false)
                setSSCCData(resp.data)
            }).catch(err => {
                setIsLoading(false)
            })
        }
        getSSCCData()
    }, [sscc])

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar sx={{backgroundColor:'#e6e6e6'}}>
                    <Toolbar>
                        <Box component="img"
                        src={Logo}
                        sx={{height:56}}
                        />
                    </Toolbar>
                </AppBar>
                {
                    isLoading ? <Backdrop
                        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
                        open={isLoading}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                        :
                        <Grid container spacing={2} sx={{ paddingTop: 10, paddingLeft: 2 }}>
                            <Grid item xs={6} md={3}>Name</Grid>
                            <Grid item xs={6} md={8}>{ssccData.product.name}</Grid>
                            <Grid item xs={6} md={3}>CAS No.</Grid>
                            <Grid item xs={6} md={8}>{ssccData.product.cas_no}</Grid>
                            <Grid item xs={6} md={3}>License No.</Grid>
                            <Grid item xs={6} md={8}>{ssccData.location.license_no}</Grid>
                            <Grid item xs={6} md={3}>Manufactured By</Grid>
                            <Grid item xs={6} md={8}>{ssccData.location.name}<br/>{ssccData.location.address}</Grid>
                            <Grid item xs={6} md={3}>Manufactured Date</Grid>
                            <Grid item xs={6} md={8}>{ssccData.mfg_date}</Grid>
                            <Grid item xs={6} md={3}>Batch No</Grid>
                            <Grid item xs={6} md={8}>{ssccData.batch_no}</Grid>
                            <Grid item xs={6} md={3}>Retest Date</Grid>
                            <Grid item xs={6} md={8}>{ssccData.retest_date}</Grid>
                            <Grid item xs={6} md={3}>Gross Weight</Grid>
                            <Grid item xs={6} md={8}>{ssccData.gross_wt}</Grid>
                            <Grid item xs={6} md={3}>Tare Weight</Grid>
                            <Grid item xs={6} md={8}>{ssccData.tare_wt}</Grid>
                            <Grid item xs={6} md={3}>Net Weight</Grid>
                            <Grid item xs={6} md={8}>{ssccData.net_wt}</Grid>
                            <Grid item xs={6} md={3}>Container No</Grid>
                            <Grid item xs={6} md={8}>{ssccData.container_no}</Grid>
                            <Grid item xs={6} md={3}>Total Containers</Grid>
                            <Grid item xs={6} md={8}>{ssccData.total_containers}</Grid>
                            <Grid item xs={6} md={3}>Storage</Grid>
                            <Grid item xs={6} md={8}>{ssccData.product.storage_condition}</Grid>
                            <Grid item xs={6} md={3}>SSCC</Grid>
                            <Grid item xs={6} md={8}>{ssccData.sscc_code}</Grid>
                        </Grid>
                }

            </Box>
        </React.Fragment>
    )
}